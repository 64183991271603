@import url(http://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,700italic,300,400,700);
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Variables

$edit: #5bc0de;
$delete: #d9534f;
$create: #337ab7;
$success: #5cb85c;
$warning: #f0ad4e;

// Styling

body {
	padding-top: 20px;
	padding-bottom: 20px;
}

.center {
	text-align: center;
}

/*
 * GENERAL
 */
 
 
.panel-heading {
	 line-height:32px;
}


button {
	// Stacked buttons
	&.stacked {
		margin-right: 10px;
	}
}

span {
	&.timer, &.due-date {
		font-size: 15px;
	}
}

/* Index table styling */
table.index-table {
	tr td {
		vertical-align: middle;
		/* Styles checkbox column on index tables */
		&.checkbox-column {
			border-right: 1px solid #ddd;
			text-align: center;
			vertical-align: middle;
		}
	}
}

/* Info panel styling */
.panel.info {
	.panel-body {
		.row > div {
			margin-top: 5px;
		}
	}
	.panel-footer {
		background: transparent;
	}
}

@media(min-width: 768px) {
	/*.panel.info .panel-body .row > :nth-child(2):not(.not-offset) {
		position: relative;
		transform: translateY(50%);
	}*/
}

/*
 *	Tasks
 */
.panel {
	overflow: visible;
}

ul.task-list {
	&.first {
		padding-left: 0px;
	}
	width: 100%;
	div.alert.due-alert {
		padding: 0px 15px;
		margin-bottom: initial;
		text-align: center;
		border-color: transparent;
		color: rgba(0,0,0,0.3);
	}
	li {
		list-style-type: none;
		width: 100%;
		line-height: 45px;
		vertical-align: middle;
		border-radius: 4px;
		&:hover {
			background: #EEE;
			cursor: pointer;
		}
		&.complete {
			background-color: #dff0d8;
			/* Strikethrough text on completed task */
			a {
				text-decoration: line-through;
			}
		}
		.task-toolbar {
			a {	
				vertical-align: middle;
				font-size: 16px;
				color: #333;
				padding-left: 20px;
			}
			span {
				vertical-align: middle;
				top: -1px;
			}
			button {
				margin-right: 8.5px;
				margin-top: 5.5px;
				&.complete-task {
					&:hover {
						background: #5cb85c;
					}
				}
				&:hover {
					color: #FFF;
				}
			}
			.label {
				margin-right: 8.5px;
				margin-top: 11.5px;
				vertical-align: middle;
			}
			input {
				margin: 0px 0px 0px 18px;
				vertical-align: middle;
			}
			div.btn-group {
				margin-right: 6px;
				button {
					margin-top: 5.5px;
					margin-right: 0px;
					&.start-session-on-task {
						&:hover {
							background: $success;
						}
					}
				}
				.dropdown-toggle.btn.btn-default {
					&:hover {
						background: $edit;
					}
				}
				ul.task-actions {
					li {
						padding-left: 10px;
						margin-left: 5px;
						margin-right: 5px;
						width: initial;
						a:hover {
							background-color: transparent;
							color: #FFF;
						}
						&.edit-task {
							&:hover {
								background: $edit;
							}
						}
						&.delete-task {
							&:hover {
								background: $delete;
							}
						}
						&.delegate-task {
							&:hover {
								background: $warning;
							}
						}
						&.add-sub-task {
							&:hover {
								background: $create;
							}
						}
						a {
							text-decoration: none;
						}
					}
					
				}
			}
			form {
				line-height: initial;
			}
		}	
	}
}